'use client';

import { Box, Button, Flex, Hide, Portal } from '@chakra-ui/react';
import { CfCard, layoutMinWidth, uiColors, useCfBreakpoint } from '@cryptofi/core-ui';
import mixpanel from 'mixpanel-browser';
// eslint-disable-next-line no-restricted-imports
import { useEffect, useState } from 'react';

import {
  AssetsList,
  CreateAccountCard,
  IntroductionCard,
  InvestmentsList,
  LatestTrade,
  LearnSection,
  News,
  PageLayout,
  PerformanceOverTime,
} from '~/components';
import { investButtonLabels } from '~/constants';
import {
  useFeatureSetEnabled,
  useGetFiInfo,
  useGetUser,
  useGlobalModalContext,
  useHasNewTerms,
  useOnboardingInfo,
  usePostTelemetryEvent,
  useUserInvestments,
} from '~/hooks';
import { AllAssetIds, AssetType, ChartTimeRange, telemetryEvents } from '~/types';
import { getExploreAssetsSecurities } from '~/utils';
import { hasPerformanceData, updateInvestmentValueTimeSeries } from '~/utils/investments';

import AccountCards from './_rootPageComponents/AccountCards/AccountCards';

export default function HomePage() {
  const user = useGetUser();
  const fiInfo = useGetFiInfo();
  const investments = useUserInvestments();
  const { hasNewTerms } = useHasNewTerms();

  const [selectedTimeRange, setSelectedTimeRange] = useState<ChartTimeRange>('pastDay');
  const onboardingInfo = useOnboardingInfo();
  const { isOnboarded, isRejectedOrPending, currentOnboardingStatus } = onboardingInfo;
  const { isEnabled } = useFeatureSetEnabled();
  const { onOpenModal, onOnboardingModalOpen } = useGlobalModalContext();

  useEffect(() => {
    if (user?.data) {
      mixpanel.identify(`${user.data?.fiId}-${user.data?.userAccountId}`);
    }
  }, [user?.data]);

  const { isBelowLargeBreakpoint } = useCfBreakpoint();
  const updatedInvestmentValueTimeSeries = updateInvestmentValueTimeSeries({
    investmentValueTimeSeries: user.data?.aggregateHistoricalInvestmentOvertime,
    allInvestmentsBalance: investments.data.allInvestments.totalAmountUsd.toString(),
  });
  const chartData = updatedInvestmentValueTimeSeries?.[selectedTimeRange] || [];
  const [defaultAssetId, setDefaultAssetId] = useState<AllAssetIds | undefined>();
  const [defaultAssetType, setDefaultAssetType] = useState<AssetType | undefined>();
  const featuredSecurities = getExploreAssetsSecurities(user.data?.securitiesRiskProfile);

  const showOnboarding = (currentOnboardingStatus && !isOnboarded && !hasNewTerms) || isRejectedOrPending;

  const tradePortalHeight = '5rem';
  const ctaBtnLabel =
    investments.data.allInvestments.totalAmountUsd > 0 ? investButtonLabels.trade : investButtonLabels.invest;
  const { trackEvent } = usePostTelemetryEvent();

  // Handle "Investment" button clicks; Fire tracking event, then the original onClick action
  const handleInvestButtonClick = () => {
    trackEvent(telemetryEvents.ClickedCryptoHomeInvestButtonClient);
    onOpenModal();
  };

  // determine the default asset to show in the invest modal, after feature set is loaded. only set default asset for onboarded products.
  useEffect(() => {
    const defaultSecurities = !defaultAssetId && isEnabled(['securities']) && onboardingInfo.isOnboardedSecurities;
    const defaultCrypto = !defaultAssetId && isEnabled(['crypto']) && fiInfo.data && onboardingInfo.isOnboardedCrypto;

    if (defaultSecurities) {
      setDefaultAssetId(featuredSecurities.at(0));
      setDefaultAssetType('securities');
    } else if (defaultCrypto) {
      const enabledAssets = fiInfo?.data?.enabledFeatureSet?.enabledAssets;
      const hasBTC = enabledAssets?.includes('BTC');

      setDefaultAssetId(hasBTC ? 'BTC' : enabledAssets?.sort()[0]);
      setDefaultAssetType('crypto');
    }
  }, [defaultAssetId, fiInfo.data, featuredSecurities, isEnabled, onboardingInfo]);

  // when terms have been updated after the user has signed them, direct user to onboarding flow to sign new terms.
  useEffect(() => {
    if (hasNewTerms) {
      onOnboardingModalOpen();
    }
  }, [hasNewTerms, onOnboardingModalOpen]);

  return (
    <PageLayout
      navHeading={{ text: 'Home' }}
      hideTopNavBar={!isBelowLargeBreakpoint}
      bgColor={uiColors.lighthouse()}
      defaultAssetId={defaultAssetId}
      defaultAssetType={defaultAssetType}
    >
      <Flex gap="3" w="full" flexDir={{ base: 'column', lg: 'row' }}>
        <Flex flexDir="column" gap="inherit" flexGrow="1">
          {hasPerformanceData({
            totalOrders: user.data?.totalOrders,
            investmentCount: investments.data.allInvestments.size,
          }) && (
            <PerformanceOverTime
              selectedTimeRange={selectedTimeRange}
              setSelectedTimeRange={setSelectedTimeRange}
              chartData={chartData}
              currentValue={investments.data.allInvestments.totalAmountUsd}
              isLoaded={investments.isSuccess && !investments.isLoading}
              view="portfolio"
              footerMetrics={{
                portfolioMetrics: {
                  gainLossAmount: investments.data.allInvestments.gainLossAmount.amount,
                  gainLossPercent: investments.data.allInvestments.gainLossAmount.percent,
                },
              }}
              onOpenModal={onOpenModal}
            />
          )}

          {showOnboarding && (
            <>
              <CreateAccountCard />

              <IntroductionCard />
            </>
          )}

          {/* TODO remove feature flag once robo is generally available */}
          {isEnabled(['robo']) && isOnboarded && <AccountCards />}

          {isOnboarded && <InvestmentsList />}

          <LearnSection />

          {isEnabled(['securities']) && <News />}
        </Flex>

        <Flex flexDir="column" gap="inherit" flexBasis="33%" flexShrink="0">
          <CfCard p="0">
            <AssetsList />
          </CfCard>

          <CfCard>
            <LatestTrade />
          </CfCard>
        </Flex>
      </Flex>

      <Hide above="lg">
        <Portal>
          <Flex
            position="fixed"
            bottom="0"
            zIndex="3"
            bg="white"
            w="full"
            p="4"
            borderTop="solid 1px"
            borderColor={uiColors.coolElegance()}
            minW={layoutMinWidth}
            h={tradePortalHeight}
          >
            <Button w="full" h="full" onClick={handleInvestButtonClick}>
              {ctaBtnLabel}
            </Button>
          </Flex>

          <Box mt={tradePortalHeight} />
        </Portal>
      </Hide>
    </PageLayout>
  );
}
