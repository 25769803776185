import { Flex, Heading, LinkBox, LinkOverlay, Text } from '@chakra-ui/react';
import { CfCard, formatUsd, IconDiamonds, uiColors } from '@cryptofi/core-ui';
// eslint-disable-next-line no-restricted-imports
import NextLink from 'next/link';

import { uiRoutes } from '~/routes';
import { uiConstants } from '~/theme';

const AccountCard = ({
  heading,
  subHeading,
  isOnboarded,
  balance,
  href,
}: {
  heading: 'Guided Investing' | 'Stocks & ETFs' | 'Crypto';
  subHeading: string;
  isOnboarded: boolean;
  balance: number;
  href: string;
}) => {
  return (
    <CfCard
      as={LinkBox}
      borderStyle="dashed"
      transition={`background-color ${uiConstants.transitionDuration}`}
      _hover={{
        background: uiConstants.hoverBackground,
      }}
    >
      <LinkOverlay as={NextLink} href={href} fontFamily="heading">
        <Flex>
          {heading === 'Guided Investing' && <IconDiamonds boxSize="5" mr="2" />}

          <Heading as="h2" fontSize="md" mb="1">
            {heading}
          </Heading>
        </Flex>

        <Text fontSize="sm">{subHeading}</Text>

        <Flex mt="4" justifyContent="space-between">
          <Text fontSize="sm" color={uiColors.sonicSilver()}>
            {isOnboarded ? 'Holdings' : 'Finish setting up your account'}
          </Text>

          {isOnboarded && (
            <Text fontSize="lg" fontWeight="bold">
              {formatUsd({ amount: balance })}
            </Text>
          )}
        </Flex>
      </LinkOverlay>
    </CfCard>
  );
};

export default AccountCard;
