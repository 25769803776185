import { CfCard } from '@cryptofi/core-ui';

import { uiRoutes } from '~/_core/routes';
// eslint-disable-next-line no-restricted-imports
import { useFeatureSetEnabled } from '~/hooks';

import AccountCard from './AccountCard';

const AccountCards = () => {
  const { isEnabled } = useFeatureSetEnabled();

  return (
    <CfCard
      flexDir={{
        base: 'column',
        xl: 'row',
      }}
    >
      {isEnabled(['securities']) && (
        <AccountCard
          heading="Stocks & ETFs"
          subHeading="Self-directed"
          isOnboarded={false}
          balance={0}
          href={uiRoutes.selfDirectedSecurities()}
        />
      )}

      {isEnabled(['robo']) && (
        <AccountCard
          heading="Guided Investing"
          subHeading="Stocks & ETFs"
          isOnboarded={false}
          balance={0}
          href={uiRoutes.guidedInvesting()}
        />
      )}

      {isEnabled(['crypto']) && (
        <AccountCard
          heading="Crypto"
          subHeading="Self-directed"
          isOnboarded
          balance={0}
          href={uiRoutes.selfDirectedCrypto()}
        />
      )}
    </CfCard>
  );
};

export default AccountCards;
